export default {
    common: {
        contact_us: "Contact Us",
        cancel: "Cancel",
    },
    header: {
        home: "Home",
        serve: "Services",
        football: "Football",
    },
    home: {
        page1: {
            smart_trading: "SMART TRADING",
            reliable_system: "RELIABLE SYSTEM",
            experience: "Experience The Smartest Sports Trading Service In The Industry",
            see: "We have created innovative software that has been tried and tested from every perspective.s",
            btn_try_play: "Enter The Game",
        },
        page2: {
            about_us: "About Us",
            choose: "An Easy Choice",
            knowledge:
                "Knowledgeable, reliable and scalable - we offer sports trading services that are superior to any other offering in the industry. Unrivalled in our global expertise, we have over 30 years of sportsbetting experience across European, Asian, Indian and US markets.",
            create: "We have created a strong and flexible sports trading system to provide an ultimate sports betting platform. Our goal is to constantly create the most efficient, flexible and user-friendly software for operators, providing their customers with a first class betting experience.",
            supply: "We pride ourselves in offering the best football pricing product. Nobody does football smarter than we do and we intend to roll out this approach to future sports releases too. Shortly to be made available are Cricket, Kabaddi, Basketball, Tennis, American Football and Baseball.",
        },
        page3: {
            understand: "No One Knows Football Better Than Us",
            supply: "We currently offer football and will be rolling out more sports soon including cricket, kabbadi, basketball, tennis, American football and baseball.",
            team: "First-Class Team",
            our_team:
                "Our team is made up of sports betting experts from around the world. We are proud to say that we have the smartest traders, supported by the world's most successful gaming group. If you want to be able to withstand the odds of the smartest players, work with the smartest vendors. We are the only provider that can guarantee that no successful bet will be voided, ensuring that valuable customers stay.",
            technology: "Top Technology",
            we_supply:
                "We offer fully automated pricing by the most talented quantitative analysts and data scientists, we have smart customer analytics tools that include dynamic analytics with intelligent capabilities that enable operators to maximise revenue and ensure that their risks are well managed.",
            pwerful: "Powerful Platform",
            use: "Using the thoughtful, powerful and reliable European and Asian user interface to meet all your needs - Our products allow you to set flexible markets, highest odds and the highest bet limits in the industry, it can create 100 betting markets, not to mention the longest betting times and the fastest markets available after goals and red cards. We are also the first to launch sport exchange and fixed odd betting.",
        },
        page4: {
            operator: "Operator Maximise The Benefits",
            check: "Check Out Our Services",
            intelligent: "The Smartest Trading",
            us: "We offer the smartest trading services in the world with the best odds and highest limits. Our fully automated pricing is also powered by the best quantitative analysts and data scientists in the industry.",
            revenue: "Maximise Revenue",
            use: "Take advantage of smart real-time tools such as dynamic customer profiling and flexible margins. We have hundreds of football markets, plus the highest up-time and market availability after goals and red cards. We are the only company in Asia to guarantee that no bet will be canceled therefore keeping valuable customers.",
            product: "Unique Product",
            easybet:
                "Easybet has the first product to offer sport exchange and fixed odds in the world. Be rest assured that your product is comparable to other providers.",
        },
        page5: {
            unbeatable: "Unbeatable Cricket",
            for: "We provide an unrivalled service with hundreds of markets available in a customisable style that allows you to control margins and limits, but with the flexibility to be a market leader. Your players will be able to bet on the outcome of every single ball across all formats of cricket on a product that offers more matches to bet on than any other cricket service globally. We offer the most number of cricket events in the industry - 5,000 matches vs 3,500 by other competitors in 2022.",
            focus: "Follow Kabbadi",
            kabaddi:
                "Kabaddi is a huge growth sport in the Indian market and our service here is 9 times greater than the current market leader! We will offer 27 in-play markets compared to a maximum of 3 that are widely priced by other odds providers. We also have a far superior up-time in terms of market suspensions and our range of player based bets provide a unique and creative offering for operators.",
        },
    },
    footer: {
        lang: "Available languagues",
        currency_wallet: "Currencies/ wallet features",
        any_currency: "All currencies",
        cryptocurrency: "Cryptocurrency",
        multi_currency: "Multi-currency",
        wallet: "Wallet",
        multi_wallet: "Multi-wallet",
    },
};
