export default {
    common: {
        contact_us: "联系我们",
        cancel: "取消",
    },
    header: {
        home: "主页",
        serve: "服务",
        football: "足球",
    },
    home: {
        page1: {
            smart_trading: "智能交易",
            reliable_system: "可靠的系统",
            experience: "体验业内最智能的体育交易服务",
            see: "从各个角度来看，我们创造了经过尝试和不断测试的创新软件。",
            btn_try_play: "立即试玩",
        },
        page2: {
            about_us: "关于我们",
            choose: "一个简单的选择",
            knowledge:
                "知识渊博、可靠且可扩展-我们提供优于业内任何其他产品的体育交易服务。我们的全球专业知识无与伦比，我们在欧洲、亚洲、印度和美国市场拥有超过30年的体育博彩经验。",
            create: "我们创建了强大而灵活的体育交易系统，以提供终极体育博彩平台。我们的目标是不断为运营商创建最高效、灵活和易于使用的软件，为他们的客户提供一流的投注体验。",
            supply: "我们以提供最好的足球定价产品而自豪。没有人比我们更懂足球，我们也打算将这种方法推广到未来的体育版本中。不久将提供板球、卡巴迪、篮球、网球、美式足球和棒球。",
        },
        page3: {
            understand: "没有人比我们更专业",
            supply: "我们目前提供足球，但我们正在为各种运动提供相同的精神，包括板球、卡巴迪、篮球、网球、美式足球和棒球。",
            team: "一流团队",
            our_team:
                "我们的团队由来自全球的体育博彩专家组成。我们很自豪地说，我们拥有最智能的操盘手，世界上最成功的博彩集团提供支持，強大的資金後盾保障，信譽最佳！強大的IT技術支持，我们是唯一可以保证不作废投注的供应商，确保有价值的客户不流失。",
            technology: "顶尖技术",
            we_supply:
                "我们提供全自动定价由最有才华的定量分析师和数据科学家实施，我们拥有智能的客户分析工具，其中包括具有智能功能的动态分析，使运营商能够最大限度地提高收入并确保他们的风险得到最佳管理。",
            pwerful: "功能强大",
            use: "采用欧洲和亚洲用户界面，经过精心设计功能强大且可靠，可满足您作为操作员的所有需求。我们的产品允许您设灵活的水位，最高的赔率及业内最高的投注限额，并且它能够创建100个投注市场，更不用说最高受注时间和进球和红牌之后最快开盘。我们还推出了世界上第一个提供体育交易和固定赔率投注。",
        },
        page4: {
            operator: "营运商利益最大化",
            check: "查看我们的服务",
            intelligent: "智能",
            us: "我们最智能的交易服务。自動精準運作，配合完善支援，讓新手極速上手，讓老手更上一層樓。全自动定价还由业内最优秀的定量分析师和数据科学家提供支持。",
            revenue: "最大化收入",
            use: "利用智能实时工具，例如动态客户分析和灵活利润。最高受注时间和进球和红牌之后最快开盘。我们是全亚洲唯一保证不取消注单的公司，保护优质客户不流失。",
            product: "独特的产品",
            easybet: "Easybet拥有世界上第一个提共体育交易所和固定赔率投注机会的产品。请放心，您的产品可以与其他供应商媲美。",
        },
        page5: {
            unbeatable: "无与伦比的板球",
            for: "对于板球，我们提供无与伦比的服务，数百个市场以可定制的方式提供，让您可以控制利润和限制，但可以灵活地成为市场领导者。您的会员将能够在所有形式的板球比赛中投注每一个球的结果，该产品提供的比赛比全球任何其他板球服务都多。我们提供业内最多的板球赛事 - 2022 年有 5,000 场比赛，其他竞争对手有 3,500 场比赛。",
            focus: "关注 KABADDI",
            kabaddi:
                "卡巴迪是印度市场上一项巨大的增长运动，我们在这里的服务是当前市场领导者的9倍！我们将提供27个盘中盘口，而其他赔率提供者广泛定价的最多 3 个盘口。",
        },
    },
    footer: {
        lang: "可用语言",
        currency_wallet: "货币/钱包功能",
        any_currency: "任何货币",
        cryptocurrency: "加密货币",
        multi_currency: "多货币",
        wallet: "钱包",
        multi_wallet: "多钱包",
    },
};
