<template>
    <v-app>
        <Header />
        <v-main>
            <router-view />
        </v-main>
        <Footer />
        <GoTop />
    </v-app>
</template>
<script>
import Header from "@/views/Header.vue";
import Footer from "@/views/Footer.vue";
import GoTop from "@/components/GoTop.vue";
export default {
    name: "App",

    components: {
        Header,
        Footer,
        GoTop,
    },

    data: () => ({}),
};
</script>

<style lang="scss" scoped></style>
