<template>
    <div>
        <!-- PC -->
        <v-sheet
            v-if="!$vuetify.breakpoint.mobile"
            max-width="1400"
            color="transparent"
            class="ma-auto d-flex align-center absolute pt-8 px-5"
        >
            <img width="294" height="65" src="@/assets/logo/logo.png" />
            <v-sheet color="transparent" class="ml-auto">
                <v-tabs slider-size="3" active-class="primary--text" background-color="transparent">
                    <v-tab
                        v-for="name of tabOptions"
                        :key="name"
                        @click="scrollTo(name)"
                        class="text-capitalize tab mx-9 text-22 font-weight-bold"
                    >
                        {{ $t(`header.${name}`) }}
                    </v-tab>
                </v-tabs>
            </v-sheet>
            <v-sheet color="primary" rounded="pill" class="ml-10 py-2 px-4 d-flex align-center">
                <span class="white--text text-20 mr-2"> {{ $t("common.contact_us") }} </span>
                <v-btn icon class="ml-3" @click="openLink('whatsapp')">
                    <v-img :src="require('../assets/icon/whatsapp.png')" contain height="28" width="28" />
                </v-btn>
                <v-btn icon class="ml-3" @click="openLink('telegram')">
                    <v-img :src="require('../assets/icon/telegram.png')" contain height="28" width="28" />
                </v-btn>
                <v-btn icon class="ml-3" @click="openLink('skype')">
                    <v-img :src="require('../assets/icon/skype.png')" contain height="28" width="28" />
                </v-btn>
                <v-btn icon class="ml-3" @click="openLink('mail')">
                    <v-img :src="require('../assets/icon/mail.png')" contain height="28" width="28" />
                </v-btn>
            </v-sheet>
            <v-menu offset-y rounded="xl" bottom elevation="0">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="btnBackgournd" v-bind="attrs" v-on="on" fab width="50" height="50" class="ml-5" elevation="0">
                        <span class="white--text text-18">{{ selectedLang }}</span>
                    </v-btn>
                </template>
                <!-- <v-list dense>
                    <v-list-item-group mandatory @change="changeLang" v-model="selectedLang" color="primary">
                        <v-list-item :value="item" link v-for="(item, index) in langOptions" :key="index">
                            <v-list-item-title>{{ item }}</v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                </v-list> -->
                <template v-for="(item, index) in langOptions">
                    <v-btn
                        color="white"
                        @click="changeLang(item)"
                        v-if="item != selectedLang"
                        :key="index"
                        fab
                        width="50"
                        height="50"
                        elevation="0"
                    >
                        <span class="text-18">{{ item }}</span>
                    </v-btn>
                </template>
            </v-menu>
        </v-sheet>

        <!-- mobile -->
        <v-sheet v-else color="transparent" class="ma-auto d-flex justify-end align-center pt-2 absolute px-5 flex-wrap">
            <img width="117" height="26" src="@/assets/logo/logo.png" />
            <v-sheet color="transparent" class="ml-auto mr-2">
                <v-tabs slider-size="0" active-class="primary--text" background-color="transparent">
                    <v-tab
                        v-for="name of tabOptions"
                        :key="name"
                        @click="scrollTo(name)"
                        active-class="active-tab"
                        class="text-capitalize text-12 tab mx-2 font-weight-bold"
                    >
                        {{ $t(`header.${name}`) }}
                    </v-tab>
                </v-tabs>
            </v-sheet>
            <ContactUsDialogVue />
            <v-menu offset-y rounded="xl" transition="slide-y-transition" elevation="0">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="btnBackgournd" v-bind="attrs" v-on="on" fab width="28" height="28" class="mx-1" elevation="0">
                        <span class="white--text text-12">{{ selectedLang }}</span>
                    </v-btn>
                </template>
                <template v-for="(item, index) in langOptions">
                    <v-btn
                        color="white"
                        @click="changeLang(item)"
                        v-if="item != selectedLang"
                        :key="index"
                        fab
                        width="28"
                        height="28"
                        elevation="0"
                    >
                        <span class="text-12">{{ item }}</span>
                    </v-btn>
                </template>
            </v-menu>
        </v-sheet>
    </div>
</template>
<script>
import ContactUsDialogVue from "@/components/ContactUsDialog.vue";
import link from "@/core/link";
export default {
    name: "Header",

    components: {
        ContactUsDialogVue,
    },

    data: () => ({
        langOptions: ["CN", "EN"],
        selectedLang: "",
        tabOptions: ["home", "serve", "football"],
    }),

    methods: {
        changeLang(lang) {
            this.selectedLang = lang;
            this.$i18n.locale = this.selectedLang == "CN" ? "zh" : "en";
        },

        openLink(key) {
            window.open(link[key]);
        },

        scrollTo(id) {
            const element = document.getElementById(id);
            this.$vuetify.goTo(element.offsetTop);
        },
    },

    created() {
        this.selectedLang = this.$i18n.locale == "zh" ? "CN" : "EN";
    },
};
</script>
<style lang="scss" scoped>
.absolute {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
    color: $text-color;
}
.tab {
    min-width: unset;
    padding: 0;
    &::before {
        background: transparent;
    }
}
</style>
