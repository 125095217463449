<template>
    <v-dialog v-model="dialog" width="200">
        <template v-slot:activator="{ on, attrs }">
            <v-img v-bind="attrs" v-on="on" class="mx-1" :src="require('../assets/icon/message.png')" height="28" max-width="28" />
        </template>
        <v-sheet color="#fff" class="d-flex flex-column py-5">
            <p class="text-16 font-weight-bold text-center">{{ $t("common.contact_us") }}</p>
            <div class="d-flex justify-center">
                <v-img
                    @click="openLink('wapWhatsapp')"
                    class="mx-2"
                    :src="require('../assets/icon/whatsapp1.png')"
                    contain
                    height="26"
                    max-width="26"
                />
                <v-img
                    @click="openLink('telegram')"
                    class="mx-2"
                    :src="require('../assets/icon/telegram1.png')"
                    contain
                    height="26"
                    max-width="26"
                />
                <v-img
                    @click="openLink('wapSkype')"
                    class="mx-2"
                    :src="require('../assets/icon/skype1.png')"
                    contain
                    height="26"
                    max-width="26"
                />
                <v-img
                    @click="openLink('mail')"
                    class="mx-2"
                    :src="require('../assets/icon/mail1.png')"
                    contain
                    height="26"
                    max-width="26"
                />
            </div>
            <v-btn @click="dialog = false" width="100" height="28" class="text-capitalize skewX-10 shadow mt-5 mx-auto" color="primary">
                <p class="text-center ma-0 text-14 font-weight-bold skewX10">{{ $t("common.cancel") }}</p>
            </v-btn>
        </v-sheet>
    </v-dialog>
</template>
<script>
import link from "@/core/link";
export default {
    name: "CustomDialog",
    components: {},
    data: () => ({
        dialog: false,
    }),
    methods: {
        openLink(key) {
            window.open(link[key]);
        },
    },
};
</script>
<style lang="scss" scoped>
.shadow {
    box-shadow: 0 0 20px 0 #b2baef !important;
}

.skewX-10 {
    transform: skewX(-10deg);
}

.skewX10 {
    transform: skewX(10deg);
}
</style>
