import Vue from "vue";
import App from "./App.vue";
import vuetify from "@/plugins/vuetify";
import router from "@/router";
import i18n from "@/plugins/i18n";
import "@/styles/common.scss";
import VueMeta from "vue-meta";

Vue.config.productionTip = false;
Vue.use(VueMeta, {
    refreshOnceOnNavigation: true,
});
new Vue({
    router,
    i18n,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");
