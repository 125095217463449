import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

const opts = {
    theme: {
        themes: {
            light: {
                primary: "#3f53d8",
                background: "#35495e",
                error: "#d63031",
                info: "#0984e3",
                secondary: "#fdcb6e",
                success: "#00cec9",
                surface: "#6c5ce7",
                warning: "#2d3436",

                black: "#0f1928",
                textBlue: "#b2baef",
                btnBackgournd: "#9fa3a9",
                textGray: "#6f757e",
                copyright: "#3f4753",
            },
            dark: false,
            variables: {},
        },
    },
};

export default new Vuetify(opts);
