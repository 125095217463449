import Vue from "vue";
import VueRouter from "vue-router";

// RouterTab 内置路由
// import { RouterTabRoutes } from 'vue-router-tab'

// 异步加载页面组件
const importPage = (view) => () => import(/* webpackChunkName: "p-[request]" */ `../views/${view}.vue`);

Vue.use(VueRouter);

const routes = [
    // 引入 RouterTab 内置路由以支持 iframe 页签
    //   ...RouterTabRoutes,
    {
        path: "/", // 默认页和父级路由一致
        name: "PageHome",
        component: importPage("PageHome"),
        meta: {
            title: "PageHome", // 页签标题
        },
    },
    //   {
    //     path: '/about',
    //     name: 'About',
    //     component: importPage('About'),
    //     meta: {
    //       title: 'About' // 页签标题
    //     }
    //   },
    //   {
    //     path: '/page/:id',
    //     name: 'Page',
    //     component: importPage('Page'),
    //     meta: {
    //       key: 'path',
    //       title(route) {
    //         return `Page ${route.params.id}`
    //       }
    //     }
    //   }
];

const router = new VueRouter({ routes });

export default router;
