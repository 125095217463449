<template>
    <v-btn
        :x-small="$vuetify.breakpoint.mobile"
        v-scroll="onScroll"
        v-show="fab"
        fab
        fixed
        bottom
        right
        color="btnBackgournd"
        @click="toTop"
    >
        <v-img max-width="100%" :height="$vuetify.breakpoint.mobile ? 15 : 20" contain src="@/assets/icon/go_top.png"></v-img>
    </v-btn>
</template>
<script>
export default {
    name: "Header",
    components: {},
    data: () => ({
        fab: false,
    }),
    methods: {
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.fab = top > 20;
        },
        toTop() {
            this.$vuetify.goTo(0);
        },
    },
};
</script>
<style lang="scss" scoped></style>
