<template>
    <v-sheet :height="$vuetify.breakpoint.mobile ? 'auto' : 250" color="black">
        <!-- PC -->
        <template v-if="!$vuetify.breakpoint.mobile">
            <v-sheet color="transparent" max-width="1400" height="100%" class="ma-auto d-flex align-center px-5">
                <img width="200" src="@/assets/logo/logo.png" />
                <!-- <div class="ml-10">
                    <div class="textGray--text text-14 my-5">
                        <span class="mx-3">{{ $t("footer.lang") }}</span>
                        <span class="mx-3">{{ "中文简体" }}</span>
                        <span class="mx-3">{{ "中文繁體" }}</span>
                        <span class="mx-3">{{ "English" }}</span>
                        <span class="mx-3">{{ "ViệtName" }}</span>
                    </div>
                    <div class="textGray--text text-14 my-5">
                        <span class="mx-3">{{ $t("footer.currency_wallet") }}</span>
                        <span class="mx-3">{{ $t("footer.any_currency") }}</span>
                        <span class="mx-3">{{ $t("footer.cryptocurrency") }}</span>
                        <span class="mx-3">{{ $t("footer.multi_currency") }}</span>
                        <span class="mx-3">{{ $t("footer.wallet") }}</span>
                        <span class="mx-3">{{ $t("footer.multi_wallet") }}</span>
                    </div>
                    <div class="copyright--text text-14 my-5">
                        <span class="mx-3">{{ "©ALIMAMA MUX, powered by alimama THX." }}</span>
                    </div>
                </div> -->
                <div class="ml-auto d-flex flex-column">
                    <span class="textGray--text text-14 mb-3">{{ $t("common.contact_us") }}</span>
                    <div class="d-flex">
                        <v-img
                            @click="openLink('whatsapp')"
                            class="mr-5 cursor-pointer"
                            width="25"
                            height="25"
                            src="@/assets/icon/whatsapp1.png"
                        ></v-img>
                        <v-img
                            @click="openLink('telegram')"
                            class="mr-5 cursor-pointer"
                            width="25"
                            height="25"
                            src="@/assets/icon/telegram1.png"
                        ></v-img>
                        <v-img
                            @click="openLink('skype')"
                            class="mr-5 cursor-pointer"
                            width="25"
                            height="25"
                            src="@/assets/icon/skype1.png"
                        ></v-img>
                        <v-img
                            @click="openLink('mail')"
                            class="mr-5 cursor-pointer"
                            width="25"
                            height="25"
                            src="@/assets/icon/mail1.png"
                        ></v-img>
                    </div>
                </div>
            </v-sheet>
        </template>

        <!-- mobile -->
        <template v-else>
            <v-sheet color="transparent" height="100%" class="d-flex flex-column pa-5 pr-0">
                <img width="100" src="@/assets/logo/logo.png" />
                <!-- <div>
                    <div class="textGray--text text-12 mt-4">
                        <span class="mr-3">{{ $t("footer.lang") }}</span>
                        <span class="mr-3">{{ "中文简体" }}</span>
                        <span class="mr-3">{{ "中文繁體" }}</span>
                        <span class="mr-3">{{ "English" }}</span>
                        <span class="mr-3">{{ "ViệtName" }}</span>
                    </div>
                    <div class="textGray--text text-12 mt-4 d-flex flex-wrap">
                        <span class="mr-3">{{ $t("footer.currency_wallet") }}</span>
                        <span class="mr-3">{{ $t("footer.any_currency") }}</span>
                        <span class="mr-3">{{ $t("footer.cryptocurrency") }}</span>
                        <span class="mr-3">{{ $t("footer.multi_currency") }}</span>
                        <span class="mr-3">{{ $t("footer.wallet") }}</span>
                        <span class="mr-3">{{ $t("footer.multi_wallet") }}</span>
                    </div>
                </div> -->
                <span class="textGray--text text-14 mt-4 mb-2">{{ $t("common.contact_us") }}</span>
                <div class="d-flex">
                    <v-img
                        @click="openLink('wapWhatsapp')"
                        class="cursor-pointer mr-4"
                        contain
                        max-width="25"
                        height="25"
                        src="@/assets/icon/whatsapp1.png"
                    ></v-img>
                    <v-img
                        @click="openLink('telegram')"
                        class="cursor-pointer mr-4"
                        contain
                        max-width="25"
                        height="25"
                        src="@/assets/icon/telegram1.png"
                    ></v-img>
                    <v-img
                        @click="openLink('wapSkype')"
                        class="cursor-pointer mr-4"
                        contain
                        max-width="25"
                        height="25"
                        src="@/assets/icon/skype1.png"
                    ></v-img>
                    <v-img
                        @click="openLink('mail')"
                        class="cursor-pointer mr-4"
                        contain
                        max-width="25"
                        height="25"
                        src="@/assets/icon/mail1.png"
                    ></v-img>
                </div>
                <!-- <span class="copyright--text text-12 mt-4">{{ "©ALIMAMA MUX, powered by alimama THX." }}</span> -->
            </v-sheet>
        </template>
    </v-sheet>
</template>
<script>
import link from "@/core/link";
export default {
    name: "Header",
    components: {},
    data: () => ({}),
    methods: {
        openLink(key) {
            window.open(link[key]);
        },
    },
};
</script>
<style lang="scss" scoped></style>
