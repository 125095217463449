import Vue from "vue";
import VueI18n from "vue-i18n";
import zh from "@/lang/zh";
import en from "@/lang/en";

Vue.use(VueI18n);

const messages = {
    en,
    zh,
};

let i18n = new VueI18n({
    locale: "zh",
    messages: messages,
});

export default i18n;
